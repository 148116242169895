import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EmployeeMappingForm } from '@app/reducers/orm/integration/integration-configure.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'default-employee-mapping',
  template: `
    @if (mappingForm) {
      <div class="flex flex-col gap-4">
        @if (hasControl('employee_import')) {
          <div class="flex items-center">
            <label class="checkbox">
              <input type="checkbox" class="checkbox-input" [formControl]="getMappingControl('employee_import')" />
              <span class="checkbox-indicator"></span>
              <span class="checkbox-description">{{ 'Employee import' | translate }}</span>
            </label>
          </div>
        }
        @if (hasControl('timesheet_export')) {
          <div class="flex items-center">
            <label class="checkbox">
              <input type="checkbox" class="checkbox-input" [formControl]="getMappingControl('timesheet_export')" />
              <span class="checkbox-indicator"></span>
              <span class="checkbox-description">{{ 'Timesheet export' | translate }}</span>
            </label>
          </div>
        }
        @if (hasControl('absence_export')) {
          <div class="flex items-center">
            <label class="checkbox">
              <input type="checkbox" class="checkbox-input" [formControl]="getMappingControl('absence_export')" />
              <span class="checkbox-indicator"></span>
              <span class="checkbox-description">{{ 'Absence export' | translate }}</span>
            </label>
          </div>
        }
      </div>
    }
  `,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
})
export class DefaultEmployeeMappingComponent {
  @Input() public parentForm: FormGroup;

  public get mappingForm(): FormGroup<EmployeeMappingForm> | null {
    return this.parentForm.get('default_employee_mapping') as FormGroup<EmployeeMappingForm>;
  }

  public hasControl(field: keyof EmployeeMappingForm): boolean {
    return this.mappingForm?.contains(field) ?? false;
  }

  public getMappingControl(field: keyof EmployeeMappingForm): FormControl<boolean> {
    return this.mappingForm?.get(field) as FormControl<boolean>;
  }
}
