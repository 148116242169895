import { AfterViewInit, ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SelectComponent, SelectItem } from '@sb/ui';
import pickBy from 'lodash-es/pickBy';
import { Subscription } from 'rxjs';

import { fieldChangeWithStart } from '../../../../forms/form.helper';
import {
  IntegrationEmployeeField,
  IntegrationEmployeeSync,
} from '../../../../reducers/orm/integration/integration-configure.model';
import { getAppSyncValues } from '../app-configure.helper';

export const INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EmployeeSyncComponent),
  multi: true,
};

@Component({
  selector: 'employee-sync',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <sb-select
    [items]="items"
    [formControl]="selectControl"
    [displayTree]="true"
    [searchable]="true"
    [multiSelect]="true"
    [showSelectAll]="true"
  ></sb-select>`,
  providers: [INPUT_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [SelectComponent, ReactiveFormsModule],
})
export class EmployeeSyncComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input()
  public fields: IntegrationEmployeeField[] = Object.values(IntegrationEmployeeField);

  public selectControl = new UntypedFormControl([]);
  public onTouched: () => void;
  public onModelChange: (val: Record<string, boolean>) => void;
  public items: SelectItem[] = [];
  private value: Record<string, boolean>;
  private dataSubs = new Subscription();

  public constructor(private translate: TranslateService) {}

  public ngOnInit() {
    this.items = this.fields.map((field) => ({
      text: this.translate.instant(IntegrationEmployeeSync[field]),
      value: field,
    }));
  }

  public ngAfterViewInit() {
    this.dataSubs.add(
      fieldChangeWithStart(this.selectControl).subscribe({
        next: (val: string[]) => {
          if (!val) {
            return;
          }
          this.updateVal(val);
          this.onModelChange(this.value);
        },
      }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  // called by the reactive form control
  public registerOnChange(fn: (val: Record<string, boolean>) => void) {
    // assigns to our internal model change method
    this.onModelChange = fn;
  }

  // called by the reactive form control
  public registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  public writeValue(value: Record<string, boolean>) {
    if (!value) {
      return;
    }
    const keys = Object.keys(pickBy(value));
    this.updateVal(keys);
    this.selectControl.setValue(keys);
  }

  public updateVal(values: string[]) {
    this.value = getAppSyncValues(this.fields, values);
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
  }
}
