import { CommonModule, TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { IconComponent } from '@app/+authenticated/shared/icon.component';
import { ContractSyncComponent } from '@app/+modals/integrations/configure/contract-sync/contract-sync.component';
import { EmployeeSyncComponent } from '@app/+modals/integrations/configure/employee-sync/employee-sync.component';
import { Features } from '@app/enums';
import { ModalFieldComponent } from '@app/forms/modal-field.component';
import { ValidationService } from '@app/forms/validation.service';
import { DecodeHtmlStringPipe } from '@app/pipes/decode-html-string.pipe';
import { TranslationParamsPipe } from '@app/pipes/translation-params.pipe';
import { AppState } from '@app/reducers';
import { MessageAction } from '@app/reducers/message/message.action';
import { IntegrationAppModel, IntegrationAppTitle } from '@app/reducers/orm/integration-apps/integration-app.model';
import { getIntegrationAppByTitle } from '@app/reducers/orm/integration-apps/integration-app.selector';
import {
  AuthenticateResponse,
  EmployeeMappingForm,
  IntegrationContractSync,
  IntegrationContractSyncDefaultValues,
  IntegrationEmployeeSync,
  IntegrationEmployeeSyncDefaultValues,
} from '@app/reducers/orm/integration/integration-configure.model';
import { IntegrationService } from '@app/reducers/orm/integration/integration.service';
import { FeatureService } from '@app/startup/feature.service';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipDirective } from '@sb/tooltip';
import { ContentStateComponent } from '@shared/content-state/content-state.component';
import { SharedLaddaModule } from '@shared/shared-ladda.module';
import omit from 'lodash-es/omit';
import pick from 'lodash-es/pick';
import { of as observableOf, Subscription } from 'rxjs';
import { finalize, switchMap, take, tap } from 'rxjs/operators';

import { RouteModalComponent } from '../../route-modal.component';
import { appConfigureConfig, ConfigureAction, ConfigureConfig, ConfigureMode } from './app-configure.model';
import { DefaultEmployeeMappingComponent } from './default-employee-mapping/default-employee-mapping.component';

@Component({
  selector: 'modal-configure',
  templateUrl: './app-configure.component.html',
  standalone: true,
  imports: [
    RouteModalComponent,
    CommonModule,
    TranslateModule,
    TranslationParamsPipe,
    IconComponent,
    ReactiveFormsModule,
    ModalFieldComponent,
    SharedLaddaModule,
    ContentStateComponent,
    DecodeHtmlStringPipe,
    TooltipDirective,
    ContractSyncComponent,
    EmployeeSyncComponent,
    DefaultEmployeeMappingComponent,
  ],
})
export class AppConfigureComponent implements OnInit, OnDestroy {
  @ViewChild(RouteModalComponent, { static: true })
  public modal: RouteModalComponent;

  public action: ConfigureAction;
  public mode: ConfigureMode;
  public isLoading: boolean;

  public integration: IntegrationAppModel;
  public integrationConfig;
  public appConfig: ConfigureConfig;
  public authenticateResponse: AuthenticateResponse;

  public configureAction = ConfigureAction;
  public configureMode = ConfigureMode;

  public authenticateForm = new UntypedFormGroup({});
  public configureForm = new UntypedFormGroup({
    mappingName: new UntypedFormControl('', [Validators.required]),
  });

  private dataSubs = new Subscription();

  public constructor(
    private route: ActivatedRoute,
    private integrationService: IntegrationService,
    private store: Store<AppState>,
    private translate: TranslateService,
    private titleCasePipe: TitleCasePipe,
    private router: Router,
    private readonly featureService: FeatureService,
  ) {}

  public ngOnInit() {
    const currentApp = this.route.snapshot.paramMap.get('app');
    this.appConfig = appConfigureConfig[currentApp];

    this.setupForms();

    if (!this.featureService.isFeatureActivated(Features.TMP_PERSONIO_DEFAULT_EMPLOYEE_MAPPING)) {
      this.configureForm.removeControl('default_employee_mapping');
    }

    this.dataSubs.add(
      this.store.select(getIntegrationAppByTitle(currentApp as IntegrationAppTitle)).subscribe({
        next: (integration) => {
          this.integration = integration;
        },
      }),
    );

    this.dataSubs.add(
      this.route.paramMap
        .pipe(
          tap((paramMap: ParamMap) => {
            this.mode = paramMap.has('id') ? ConfigureMode.EDIT : ConfigureMode.ADD;
            this.action = ConfigureAction.CONFIGURE;
            if (paramMap.has('id')) {
              this.isLoading = true;
              return;
            }

            if (!this.appConfig.authForm?.length) {
              return;
            }
            this.action = ConfigureAction.AUTHENTICATE;
          }),
          switchMap((paramMap: ParamMap) => {
            if (paramMap.has('id')) {
              return this.integrationService.fetchIntegration(paramMap.get('id'));
            }
            return observableOf(null);
          }),
        )
        .subscribe({
          next: (integrationConfig) => {
            if (!integrationConfig) {
              return;
            }
            this.setupConfig(integrationConfig);
          },
        }),
    );
  }

  public authenticate() {
    if (this.authenticateForm.pristine || !this.authenticateForm.valid) {
      return;
    }

    this.isLoading = true;

    const data = {
      api_app_id: this.appConfig.appId,
      config: {
        ...this.authenticateForm.value,
      },
    };

    if (this.mode === ConfigureMode.EDIT) {
      data['id'] = parseInt(this.integrationConfig.id, 10);
    }

    this.dataSubs.add(
      this.integrationService.authenticateIntegration(data).subscribe({
        next: (response: AuthenticateResponse) => {
          this.action = ConfigureAction.CONFIGURE;
          this.authenticateResponse = response;

          if (response.default_employee_mapping) {
            const mappingGroup = new FormGroup<EmployeeMappingForm>({
              employee_import: new FormControl(response.default_employee_mapping.employee_import),
              timesheet_export: new FormControl(response.default_employee_mapping.timesheet_export),
              absence_export: new FormControl(response.default_employee_mapping.absence_export),
            });

            if (this.featureService.isFeatureActivated(Features.TMP_PERSONIO_DEFAULT_EMPLOYEE_MAPPING)) {
              this.configureForm.addControl('default_employee_mapping', mappingGroup);
            }
          }

          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          console.error(error);
        },
      }),
    );
  }

  // Tech-debt: should be refactored when we create a common app configure for all our integrations
  // eslint-disable-next-line max-lines-per-function
  public configure() {
    if (this.configureForm.pristine || !this.configureForm.valid) {
      return;
    }

    this.isLoading = true;

    const configureForm = this.configureForm.value;

    let data = {
      api_app_id: this.appConfig.appId,
      name: configureForm.mappingName,
      config: {
        ...omit(configureForm, ['mappingName', 'sync_contract', 'sync_employee']),
        ...configureForm.sync_contract,
        ...configureForm.sync_employee,
        ...configureForm.default_employee_mapping,
      },
    };

    if (this.appConfig.authKeysInConfigure?.length && this.authenticateForm.valid) {
      data = {
        ...data,
        config: {
          ...data.config,
          ...pick(this.authenticateForm.value, this.appConfig.authKeysInConfigure),
        },
      };
    }

    const { contractFields, employeeFields } = this.appConfig;

    if (contractFields?.length) {
      data = {
        ...data,
        config: {
          ...data.config,
          ...this.authenticateForm.value,
          ...configureForm.sync_contract,
        },
      };
    }

    if (employeeFields?.length) {
      data = {
        ...data,
        config: {
          ...data.config,
          ...configureForm.sync_employee,
        },
      };
    }

    if (this.mode === ConfigureMode.ADD) {
      this.dataSubs.add(
        this.integrationService
          .configureIntegration(data)
          .pipe(finalize(() => this.integrationService.load().pipe(take(1)).subscribe()))
          .subscribe({
            next: (response) => {
              this.isLoading = false;

              if (!this.appConfig.mappingUrl) {
                this.close();
                return;
              }

              const url =
                'account/integrations/' +
                this.route.snapshot.paramMap.get('app') +
                '/mapping/' +
                response.Integration.id +
                '/' +
                this.appConfig.mappingUrl;

              void this.router.navigateByUrl(url);
            },
            error: (error) => {
              this.isLoading = false;
              this.close();
              console.error(error);
            },
          }),
      );

      return;
    }
    data['id'] = this.integrationConfig.id;

    this.dataSubs.add(
      this.integrationService
        .updateIntegration(data)
        .pipe(switchMap(() => this.integrationService.load().pipe(take(1))))
        .subscribe({
          next: () => {
            this.isLoading = false;
            this.close();
          },
          error: (error) => {
            this.isLoading = false;
            this.close();
            console.error(error);
          },
        }),
    );
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  public editAuthenticationInformation() {
    this.authenticateForm.reset();
    this.action = ConfigureAction.AUTHENTICATE;
  }

  public close() {
    this.modal.close();
  }

  private setupForms() {
    this.appConfig.authForm?.forEach((form) => {
      this.authenticateForm.addControl(
        form.name,
        new UntypedFormControl('', form.validators.length ? form.validators : []),
      );
    });

    this.configureForm = new UntypedFormGroup({
      mappingName: new UntypedFormControl('', [Validators.required]),
    });

    this.appConfig.configureForm?.forEach((form) => {
      this.configureForm.addControl(
        form.name,
        new UntypedFormControl('', form.validators?.length ? form.validators : []),
      );
    });

    if (this.appConfig.contractFields?.length > 0) {
      this.configureForm.addControl(
        'sync_contract',
        new UntypedFormControl(pick(IntegrationContractSyncDefaultValues, this.appConfig.contractFields)),
      );
    }

    if (this.appConfig.employeeFields?.length > 0) {
      this.configureForm.addControl(
        'sync_employee',
        new UntypedFormControl(pick(IntegrationEmployeeSyncDefaultValues, this.appConfig.employeeFields)),
      );
    }

    if (this.appConfig.autoAddEmployees) {
      this.configureForm.addControl('auto_add_employees', new UntypedFormControl(false));
    }

    if (this.appConfig.importVat) {
      this.configureForm.addControl('importVat', new UntypedFormControl(false));
    }

    if (this.appConfig.notifyImport) {
      this.configureForm.addControl(
        'notify_import',
        new UntypedFormControl('', [Validators.required, ValidationService.emailValidator]),
      );
    }

    if (this.appConfig.notifyExport) {
      this.configureForm.addControl(
        'notify_export',
        new UntypedFormControl('', [Validators.required, ValidationService.emailValidator]),
      );
    }

    if (this.appConfig.turnOverImportEmail) {
      this.configureForm.addControl(
        'turnover_import_email',
        new UntypedFormControl('', [Validators.required, ValidationService.emailValidator]),
      );
    }
  }

  private setupConfig(integrationConfig) {
    this.integrationConfig = integrationConfig;
    const configureForm = integrationConfig.config;

    if (configureForm?.default_employee_mapping) {
      const mappingGroup = new FormGroup<EmployeeMappingForm>({
        employee_import: new FormControl(configureForm.default_employee_mapping.employee_import),
        timesheet_export: new FormControl(configureForm.default_employee_mapping.timesheet_export),
        absence_export: new FormControl(configureForm.default_employee_mapping.absence_export),
      });

      if (this.featureService.isFeatureActivated(Features.TMP_PERSONIO_DEFAULT_EMPLOYEE_MAPPING)) {
        this.configureForm.addControl('default_employee_mapping', mappingGroup);
      }
    }
    const contractSync = pick(configureForm, Object.keys(IntegrationContractSync));
    const employeeSync = pick(configureForm, Object.keys(IntegrationEmployeeSync));

    this.configureForm.patchValue({
      mappingName: integrationConfig.name,
      ...configureForm,
      sync_contract: contractSync,
      sync_employee: employeeSync,
    });

    this.authenticateForm.patchValue(configureForm);
    this.isLoading = false;

    if (integrationConfig.authenticated || !this.appConfig.authForm?.length) {
      this.action = ConfigureAction.CONFIGURE;
      return;
    }
    this.store.dispatch(MessageAction.error(this.translate.instant('Your credentials have expired')));
    this.action = ConfigureAction.AUTHENTICATE;
  }

  public get displayEmployeeDefaultMapping(): boolean {
    return (
      this.featureService.isFeatureActivated(Features.TMP_PERSONIO_DEFAULT_EMPLOYEE_MAPPING) &&
      ((this.mode === ConfigureMode.ADD && !!this.authenticateResponse?.default_employee_mapping) ||
        (this.mode === ConfigureMode.EDIT && !!this.integrationConfig?.config?.default_employee_mapping))
    );
  }
}
