import { getArrayDifference } from '../../../shared/array.helper';
import {
  IntegrationContractField,
  IntegrationEmployeeField,
} from '../../../reducers/orm/integration/integration-configure.model';

/**
 * Backend requires unselected values also to be in the post body with a false value
 *
 * @param availableFields
 * @param selectedValues
 */
export const getAppSyncValues = (
  availableFields: IntegrationEmployeeField[] | IntegrationContractField[],
  selectedValues: string[]
) => {
  const valueObj = {};
  selectedValues.forEach((val) => {
    valueObj[val] = true;
  });
  const falseValues = getArrayDifference(availableFields, selectedValues);
  falseValues.forEach((val) => {
    valueObj[val] = false;
  });
  return valueObj;
};
